<template>
  <!-- <iframe src="./static/test.html" frameborder="0"></iframe> -->
  <div class="ffff">
    <FuyouMain/>
  </div>
</template>

<script>
// import { selectList, selectListPd } from '@/api/seller.js'
// import { subAuction } from '@/api/jingpai.js'
import FuyouMain from '../../components/fuyoupay/fuyouMain.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    FuyouMain
  },
  methods: {
    initdata () {
      const data = {
        amount: 10,
        bankCode: 'string',
        ip: '127.0.0.1',
        openId: '1001',
        orderPayType: 'B2C',
        password: '4ea8abc2e53b171fddfb836e37c1861f',
        payeeAccount: '6226666047486316,6226666047486111',
        payeeAccountType: 'virtualBankCard,bankCard',
        payeeUserAccount: 'BU1655115016672127',
        paymentAccount: 'BU1655115016672127',
        paymentMethod: 'FUIOU_PAY',
        paymentUserAccount: 'BU1655115016672127',
        tradeType: 'MWEB'
      }
      // const res = http.post('/mall-finance/finance/capital/inCapital', data, true)
      // res.then(aa => {
      //   console.log(aa)
      //   this.aaaa = aa.data.data
      //   console.log(this.aaaa)
      //   // this.aaaa = this.aaaa.replace(/[\'\"\\\/\b\f\n\r\t]/g, '')
      //   // 读取本地保存的html数据，使用新窗口打开
      //   var newWin = window.open('', '_blank')
      //   newWin.document.write(localStorage.getItem('callbackHTML'))
      //   // 关闭输出流
      //   newWin.document.close()
      // })
    }
  }
}
</script>

<style scoped>

/* iframe{
  height: 800px;
  width: 100%;
} */
  .ffff{
    height: 1000px;
  }
</style>
