<template>
  <div>
    <el-button @click="pay">提交</el-button>
    <div v-show="true">
      <form name='yeepay' class="form-horizontal" method="post" :action="redirectUrl">
        <div class="form-group">
          <label class="col-sm-3 control-label">mchnt_cd</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="mchnt_cd"
              name="mchnt_cd" v-model="mchnt_cd">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">order_id</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="order_id"
              name="order_id" v-model="order_id">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">order_amt</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="order_amt"
              name="order_amt" v-model="order_amt">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">order_pay_type</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="order_pay_type"
              name="order_pay_type" v-model="order_pay_type">
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">page_notify_url</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="page_notify_url"
              name="page_notify_url"
              v-model="page_notify_url">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">back_notify_url</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="back_notify_url"
              name="back_notify_url"
              v-model="back_notify_url">
          </div>

        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">order_valid_time</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="order_valid_time"
              name="order_valid_time" v-model="order_valid_time">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">iss_ins_cd</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="iss_ins_cd"
              name="iss_ins_cd" v-model="iss_ins_cd">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">goods_name</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="goods_name"
              name="goods_name" v-model="goods_name">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">goods_display_url</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="goods_display_url"
              name="goods_display_url"
              v-model="goods_display_url">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">rem</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="rem" name="rem"
              v-model="rem">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">ver</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="ver" name="ver"
              v-model="ver">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">md5</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="md5" name="md5"
              v-model="md5">
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-3 col-sm-9">
            <button type="button" class="btn btn-default" @submit.prevent="pay">提交</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
// import { reactive } from 'vue-demi'
// import axios from 'axios'
// import Qs from 'qs'

export default {
  name: 'fuyouPay',
  data () {
    return {
      // path: '',
      redirectUrl: 'http://www-2.wg.fuioupay.com:13195/smpGate.do',
      mchnt_cd: '0001000F0040992',
      order_id: '20220629193723',
      order_amt: 100,
      order_pay_type: 'B2C',
      page_notify_url: 'http://www-2.wg.fuioupay.com:13195/pay_test/callback.jsp',
      back_notify_url: 'http://www-2.wg.fuioupay.com:13195/pay_test/callback.jsp',
      order_valid_time: '10m',
      iss_ins_cd: '0000000000',
      goods_name: '充值',
      goods_display_url: 'http://www-2.wg.fuioupay.com:13195/pay_test/callback.jsp',
      rem: 'rem',
      ver: '1.0.1',
      md5: ref(''),

      mchnt_key: 'vau6p7ldawpezyaugc0kopdrrwm4gkpu'
    }
  },
  components: {},
  methods: {
    pay () {
      console.log(new Date().getTime())
      this.order_id = new Date().getTime()
      const plain = this.mchnt_cd + '|' +
        this.order_id + '|' +
        this.order_amt + '|' +
        this.order_pay_type + '|' +
        this.page_notify_url + '|' +
        this.back_notify_url + '|' +
        this.order_valid_time + '|' +
        this.iss_ins_cd + '|' +
        this.goods_name + '|' +
        this.goods_display_url + '|' +
        this.rem + '|' +
        this.ver + '|' +
        this.mchnt_key
      this.md5 = this.$md5(plain)
      console.log(plain)
      console.log(this.md5)
      // document.qu
      // let data = {
      //   mchnt_cd: '0001000F0040992',
      //   order_id: 20220629153203,
      //   order_amt: 1,
      //   order_pay_type: 'B2C',
      //   page_notify_url: 'http://www-2.wg.fuioupay.com:13195/pay_test/callback.jsp',
      //   back_notify_url: 'http://www-2.wg.fuioupay.com:13195/pay_test/callback.jsp',
      //   order_valid_time: '10m',
      //   iss_ins_cd: '0000000000',
      //   goods_name: 'nike耐克',
      //   goods_display_url: 'http://www-2.wg.fuioupay.com:13195/pay_test/callback.jsp',
      //   rem: '备注rem',
      //   ver: '1.0.1',
      //   md5: '5d10693c6e98361cd2f9f8f1cfb9a449'
      // }
      // if (this.md5) {
      // } else {
      //   this.pay()
      // }
      setTimeout(() => {
        document.yeepay.submit()
      }, 200)
    }
  },
  mounted () {

  },
  created () {
    this.path = './static/smpGateMchnt.html'
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.cont{
  // display: block;
  width: 100%;
  height: 100%;
  .post-main{
    width: 1200px;
    min-height: 300px;
    margin: 0 auto;
    background: #999999;
  }
  // width: 1000px;
  // height: 1000px;
  // border: 1px solid red;
}
</style>
